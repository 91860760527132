import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "selectors"
    }}>{`Selectors`}</h1>
    <p>{`Add `}<inlineCode parentName="p">{`wrapper-selectors`}</inlineCode>{` class on parent element to have a custom checkbox or radio input. For toggle, add `}<inlineCode parentName="p">{`toggle`}</inlineCode>{` class with `}<inlineCode parentName="p">{`wrapper-selectors`}</inlineCode>{`.
Inside parent element add an input (it's going to be invisible), an empty label with `}<inlineCode parentName="p">{`checkbox`}</inlineCode>{` class and another label element to define a label for selectors.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
  <div className='row'>
    <div className='col-sm-24 col-md-6 col-lg-4'>
      <div className='wrapper-selectors mb-3'>
        <input type='checkbox' name='checkbox' id='checkbox' />
        <label className='checkbox' htmlFor='checkbox'></label>
        <label htmlFor='checkbox'>
          Label
        </label>
      </div>
    </div>
    <div className='col-sm-24 col-md-6 col-lg-4'>
      <div className='wrapper-selectors mb-3'>
        <input type='radio' name='radio' id='radio' />
        <label className='checkbox' htmlFor='radio'></label>
        <label htmlFor='radio'>
          Label
        </label>
      </div>
    </div>
    <div className='col-sm-24 col-md-6 col-lg-4'>
      <div className='wrapper-selectors toggle mb-3'>
        <input type='checkbox' name='toggle' id='toggle' />
        <label className='checkbox' htmlFor='toggle'></label>
        <label htmlFor='toggle'>
          Label
        </label>
      </div>
    </div>
  </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  <div class='row'>
    <div class='col-md-8'>
      <div class='wrapper-selectors mb-3'>
        <input
          type='checkbox'
          name='checkbox'
          id='checkbox'
        />
        <label
          class='checkbox'
          for='checkbox'
        ></label>
        <label
          for='checkbox'
        >
          Label
        </label>
      </div>
    </div>
    <div class='col-md-8'>
      <div class='wrapper-selectors mb-3'>
        <input
          type='radio'
          name='radio'
          id='radio'
        />
        <label
          class='checkbox'
          for='radio'
        ></label>
        <label
          for='radio'
        >
          Label
        </label>
      </div>
    </div>
    <div class='col-md-8'>
      <div class='wrapper-selectors toggle mb-3'>
        <input
          type='checkbox'
          name='toggle'
          id='toggle'
        />
        <label
          class='checkbox'
          for='toggle'
        ></label>
        <label
          for='toggle'
        >
          Label
        </label>
      </div>
    </div>
  </div>
`}</code></pre>
    <h2 {...{
      "id": "disabled"
    }}>{`Disabled`}</h2>
    <p>{`Input field can show that it is disabled. Add `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` attribute/class on it.`}</p>
  <div className='row'>
    <div className='col-md-8'>
      <div className='wrapper-selectors mb-3'>
        <input type='checkbox' name='checkbox' id='checkbox' disabled />
        <label className='checkbox' htmlFor='checkbox'></label>
        <label htmlFor='checkbox'>
          Label
        </label>
      </div>
    </div>
    <div className='col-md-8'>
      <div className='wrapper-selectors mb-3'>
        <input type='radio' name='radio' id='radio' disabled />
        <label className='checkbox' htmlFor='radio'></label>
        <label htmlFor='radio'>
          Label
        </label>
      </div>
    </div>
    <div className='col-md-8'>
      <div className='wrapper-selectors toggle mb-3'>
        <input type='checkbox' name='toggle' id='toggle' disabled />
        <label className='checkbox' htmlFor='toggle'></label>
        <label htmlFor='toggle'>
          Label
        </label>
      </div>
    </div>
  </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  <div class='row'>
    <div class='col-md-8'>
      <div class='wrapper-selectors mb-3'>
        <input
          type='checkbox'
          name='checkbox'
          id='checkbox'
          disabled
        />
        <label
          class='checkbox'
          for='checkbox'
        ></label>
        <label
          for='checkbox'
        >
          Label
        </label>
      </div>
    </div>
    <div class='col-md-8'>
      <div class='wrapper-selectors mb-3'>
        <input
          type='radio'
          name='radio'
          id='radio'
          disabled
        />
        <label
          class='checkbox'
          for='radio'
        ></label>
        <label
          for='radio'
        >
          Label
        </label>
      </div>
    </div>
    <div class='col-md-8'>
      <div class='wrapper-selectors toggle mb-3'>
        <input
          type='checkbox'
          name='toggle'
          id='toggle'
          disabled
        />
        <label
          class='checkbox'
          for='toggle'
        ></label>
        <label
          for='toggle'
        >
          Label
        </label>
      </div>
    </div>
  </div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      